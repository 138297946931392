.Loader {
    @apply inline-block relative;
    width: 80px;
    height: 80px;
}
.Loader-inner {
    @apply absolute bg-primary-base;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.Loader-inner:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.Loader-inner:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.Loader-inner:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.Loader-inner:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}


.Loader-block {
    @apply bg-gray-100;
    background: linear-gradient(
                    100deg,
                    rgba(255, 255, 255, 0) 40%,
                    rgba(255, 255, 255, .5) 50%,
                    rgba(255, 255, 255, 0) 60%
    ) theme('colors.gray.100');
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s bg-loading ease-in-out infinite;
}

@keyframes bg-loading {
    to {
        background-position-x: -20%;
    }
}
