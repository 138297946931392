/*
 * [ components / icon ]
 */

// -----------------------------------------------------------------------------

[class*="Icon-"] {
    font-family: theme('fontFamily.icon');
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@each $name, $code in $icons {
    .Icon-#{$name}:before {
        content: str-unicode($code);
    }
}
