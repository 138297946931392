/*
 * [ base / default ]
 */

// -----------------------------------------------------------------------------

body,
html {
    min-width: 320px;
}

html {
    overflow-x: hidden;
}

body {
    font-family: theme('fontFamily.core');
    overflow: hidden;
    @apply text-gray-500;
}

.App {
    @apply text-base bg-white text-gray-600;
    min-height: 100vh;
}
